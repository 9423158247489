<template>
<el-dialog
    :title="'竞价结果流程图: ' + nametemp"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="80%"
    :visible.sync="dialogvisible">
    <el-steps :active="active" finish-status="success" align-center>
        <el-step title="步骤 1" description="请交费并上传缴费凭证"></el-step>
        <el-step title="步骤 2"  description="等待审核 获取竞价结果通知书"></el-step>
        <el-step title="步骤 3"  description="上传交易凭证"></el-step>
        <el-step title="步骤 4"  description="交易凭证审核"></el-step>
        <el-step title="步骤 5"  description="流程结束 交易保证金结算"></el-step>
    </el-steps>
    <el-row :gutter="20" style="margin-top:20px;margin-left:10%">
        <el-col :span="14" v-if="0<=active" style="height:260px;">
             <dv-border-box-13 style="height:260px;" :color="['#f15b6c', 'red']">
                <span style="visibility: hidden">加载中...</span>
                <ul style="margin-left:8%">
                    <li style="font-size: 20px;font-weight:600;">①下载交费通知书</li>
<!--                     <li v-show="JSON.stringify(this.active0)=='[]'" style="font-size: 14px;color:red;">等待管理员上传</li>-->
<!--                    <li v-for="(i,index) in active0" :key="index" style="margin-top:20px;">-->
                    <li  style="margin-top:20px;">
                        <div class="filein" @click="downloadFile()" v-if="oneTarget.msga == '1'">交费通知书</div>
                        <div style="font-size: 14px;color:red;float:left; list-style:none;display:block;max-width: 100%" v-else>等待管理员下发交费通知书</div>
                    </li>
                </ul>
                   <ul style="margin-left: 62%;margin-top: -50px;">
                <li style="font-size: 20px;font-weight:600;">上传交费凭证</li>
                <li v-show="oneTarget.servicestate==1" style="font-size: 14px;color:red;">审核通过</li>
                <li v-show="oneTarget.servicestate==2&&active==0"
                    style="font-size: 14px;color:red;float:left; list-style:none;display:block;max-width: 100%">已驳回,请重新上传！原因：{{servicemsg}}</li>
                    <li v-if="oneTarget.depostateone==1">扣除部分保证金</li>
                    <li v-if="oneTarget.depostateone==2">扣除全额保证金</li>
                <li v-show="active==0">
                    <a href="javascript:;" class="file">点击上传
                        <input type="file" ref="a1fileref" @change="selectFilea1">
                    </a>
                </li>
                <li v-for="(i,index) in active1" :key="index" style="margin-top:20px;">
                    <a class="filein" :href="myurl(i.url)">交费凭证{{index+1}}</a>
                    <span  v-show="active==0" style="display: inline-block;margin-left:30%;cursor:pointer" @click="removeElementa1(index, i.name)">&#9746;</span>
                </li>
             </ul>
            </dv-border-box-13>

        </el-col>
         <el-col :span="7" v-if="1<=active">
             <dv-border-box-13 style="height:260px;" :color="['#f15b6c', 'red']">
                <span style="visibility: hidden">加载中...</span>
                <ul style="margin-left:8%">
                    <li style="font-size: 20px;font-weight:600;">②下载竞价结果通知书</li>
                    <li v-show="JSON.stringify(this.active2)=='[]'" style="font-size: 14px;color:red;">
                      等待管理员<span v-if="oneTarget.servicestate!=1"> 审核并</span>上传结果通知书</li>
                    <li v-for="(i,index) in active2" :key="index" style="margin-top:20px;">
                        <div class="filein" @click="godown(i.url,'down:',0)">{{index+1}}.竞价结果通知书{{index+1}}</div>
                    </li>
                </ul>
            </dv-border-box-13>
        </el-col>
    </el-row>
    <el-row  :gutter="20" style="margin-top:20px;margin-left:10%">
        <el-col :span="14" v-if="2<=active">
           <dv-border-box-13 style="height:260px;"  :color="['#f15b6c', 'red']">
            <span style="visibility: hidden">加载中...</span>
             <ul style="margin-left:8%">
                <li style="font-size: 20px;font-weight:600;">③上传交易凭证</li>
                 <!-- <li v-show="fundstate==0" style="font-size: 14px;color:red;">审核通过</li> -->
                 <li v-show="oneTarget.fundstate==1" style="font-size: 14px;color:red;">审核通过</li>
                <li v-show="oneTarget.fundstate==2&&active==2" style="font-size: 14px;color:red;">已驳回,请重新上传!原因：{{fundmsg}}</li>
                <li v-if="oneTarget.depostatetwo==1">扣除部分保证金</li>
                <li v-if="oneTarget.depostatetwo==2">扣除全额保证金</li>

             </ul>
             <div class="foreUp">
                <div class="rightline">
                    <a href="javascript:;" class="file">交易合同
                        <input type="file" ref="a301fileref" @change="selectFilea301" :disabled="disa104">
                    </a>
                    <div style="color:red;margin-left:25%;font-size:10px;">(必传)</div>
                    <ul style="margin-left:6%">
                    <li v-for="(i,index) in active301" :key="index" style="margin-top:20px;">
                        <a class="filein" :href="myurl(i.url)">交易合同{{index+1}}</a>
                        <span  v-show="active==2" style="display: inline-block;margin-left:5%;cursor:pointer" @click="removeElementa301(index, i.name)">&#9746;</span>
                    </li>
                </ul>
                </div>
                <div  class="rightline">
                    <a href="javascript:;" class="file">交易价款凭证
                        <input type="file" ref="a302fileref" @change="selectFilea302" :disabled="disa104">
                    </a>
                    <div style="color:red;margin-left:30%;font-size:10px;">(必传)</div>
                    <ul style="margin-left:6%">
                    <li v-for="(i,index) in active302" :key="index" style="margin-top:20px;">
                        <a class="filein" :href="myurl(i.url)">交易价款凭证{{index+1}}</a>
                        <span  v-show="active==2" style="display: inline-block;margin-left:5%;cursor:pointer" @click="removeElementa302(index, i.name)">&#9746;</span>
                    </li>
                </ul>
                </div>
                <div  class="rightline">
                    <a href="javascript:;" class="file" >履约保证金凭证
                        <input type="file" ref="a303fileref" @change="selectFilea303" :disabled="disa104">
                    </a>
                    <div style="margin-left:40%;font-size:10px;">(选传)</div>
                    <ul style="margin-left:8%">
                    <li v-for="(i,index) in active303" :key="index" style="margin-top:20px;">
                        <a class="filein" :href="myurl(i.url)">履约保证金凭证{{index+1}}</a>
                        <span  v-show="active==2" style="display: inline-block;margin-left:5%;cursor:pointer" @click="removeElementa303(index, i.name)">&#9746;</span>
                    </li>
                </ul>
                </div>
                <div>
                    <a href="javascript:;" class="file">交易确认函
                        <input type="file" ref="a304fileref" @change="selectFilea304" :disabled="disa104">
                    </a>
                    <div style="margin-left:38%;font-size:10px;">(选传)</div>
                    <ul style="margin-left:8%">
                    <li v-for="(i,index) in active304" :key="index" style="margin-top:20px;">
                        <a class="filein" :href="myurl(i.url)">交易确认函{{index+1}}</a>
                        <span  v-show="active==2" style="display: inline-block;margin-left:5%;cursor:pointer" @click="removeElementa304(index, i.name)">&#9746;</span>
                    </li>
                </ul>
                </div>
             </div>

           </dv-border-box-13>
        </el-col>
        <el-col :span="7" v-if="3==active">
             <dv-border-box-13 style="height:260px;" :color="['#f15b6c', 'red']">
                <span style="visibility: hidden">加载中...</span>
                <ul style="margin-left:8%">
                    <li style="font-size: 20px;font-weight:600;">④交易凭证审核</li>
                    <li v-if="oneTarget.finalresult==0">待审核</li>
                    <li v-if="oneTarget.finalresult==1">审核通过</li>
                    <li v-if="oneTarget.finalresult==2">已驳回</li>

                    <!-- <li v-for="(i,index) in active0" :key="index" style="margin-top:20px;">
                        <a  class="filein" :href="i.url">交易凭证{{index+1}}</a>
                    </li> -->
                </ul>
            </dv-border-box-13>
        </el-col>
         <el-col :span="7" v-if="4<=active">
             <dv-border-box-13 style="height:260px;" :color="['#f15b6c', 'red']">
                <span style="visibility: hidden">加载中...</span>
                <ul style="margin-left:8%">
                    <li style="font-size: 20px;font-weight:600;">⑤流程结束</li>

                    <li v-if="oneTarget.finalresult==0">流程进行中</li>
                    <li v-if="oneTarget.finalresult==1">流程结束,交易保证金结算</li>
                    <!-- <li v-if="oneTarget.depostatethree==0">退全额保证金</li>
                    <li v-if="oneTarget.depostatethree==1">退部分保证金</li>
                    <li v-if="oneTarget.depostatethree==2">扣除全额保证金</li> -->

                    <!-- <li v-for="(i,index) in active0" :key="index" style="margin-top:20px;">
                        <a  class="filein" :href="i.url">交易凭证{{index+1}}</a>
                    </li> -->
                </ul>
            </dv-border-box-13>
        </el-col>
    </el-row>
       <span slot="footer" class="dialog-footer">
      <el-button @click="dialogvisible = false">取消</el-button>
      <el-button type="primary" @click="next" v-show="[0,2].includes(active)">提交审核</el-button>
    </span>
</el-dialog>
</template>

<script>
import UploadService from '../services/UploadFilesService'
import {mapGetters} from "vuex";
import axios from 'axios';
    export default {
        name: "personalCenter",
        data() {
            return {
                // 成交后服务费通知（含站内信通知、手机短信通知、显示下载通知书）1:打开通知，0：关闭通知
                nametemp: '',
                oneTarget: {
                    servicestate:0
                },
                disa104: false,
                a2role: 0, // a2 审核通过 1  不过=2 ，默认0
                dataid: null, //当前targetid 的序号
                active: 3, // 步骤条
                // 6个下载地址--具体文件列表
                active0: [],
                active1: [],
                active2: [],
                active301: [],
                active302: [],
                active303: [],
                active304: [],
                targetid:[],
                dialogvisible: false,
                // 附件地址--文件夹名
                a0addr: null,
                a1addr: null,
                a2addr: null,
                a301addr: null,
                a302addr: null,
                a303addr: null,
                a304addr: null,
                currentFile: null,
                currentFile301: null,
                currentFile302: null,
                currentFile303: null,
                currentFile304: null,
                a1UpFiles: null,
                a301UpFiles: null,
                a302UpFiles: null,
                a303UpFiles: null,
                a304UpFiles: null,
                progress: 0, //线形进度条
                servicemsg: '',
                fundmsg: ''
            }// return end
        },
        mounted() {
            // this.init()
        },
        activated() {

        },
        methods: {
            // 导出word
            saveFile(data, name) {
                try {
                    const blobUrl = window.URL.createObjectURL(data)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.download = name
                    a.href = blobUrl
                    a.click()
                } catch (e) {
                    this.$message.error('文件下载失败，请稍后重试！')
                }
            },
            ...mapGetters(["getUser", "getNum"]),
            // ---------------------------------- 以下
            downloadFile () {
                axios.post( '/shoppingui/bidShopping/exportWord', {
                    targetid: this.targetid,
                    memberid: this.getUser().id,
                }, {
                    responseType: 'blob'
                }).then(function(response) {

                    var fileName = '交费通知书.doc'
                    const blob = new Blob([response.data],{type: 'application/octet-stream'})
                    try {
                        const blobUrl = window.URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.style.display = 'none'
                        a.download = fileName
                        a.href = blobUrl
                        a.click()
                    } catch (e) {
                        this.$message.error('文件下载失败，请稍后重试！')
                    }
                    })
            },

            // ----------------- 导出word 以上
            myurl(url){
              if(url!=null){
                var reg = RegExp(/shoppingui/);

                if(reg.test(url)){
                  const index = url.indexOf("shoppingui")
                  const res = this.$urldown + url.substring(index)
                  return res
                }
              }else {
                return null
              }
            },
            godown(url, type,finalres){
              const urltemp = this.myurl(url)
               this.$fetch('/shoppingui/bidShopping/delteredisresult', {
                 id: this.dataid,
                 targetid: this.targetid,
                 memberid: this.getUser().id,
                 upordown: type,
                 finalres: finalres
                 },true).then((data) => {

                  if(urltemp!=null)
                   window.open(urltemp)
               })
            },
            init (id,name) {
              this.nametemp = name
                this.disa104=false,
                this.a2role= 0, // a2 审核通过 1  不过=2 ，默认0
                this.dataid= null, //当前targetid 的序号
                this.active=3, // 步骤条
                // 6个下载地址--具体文件列表
                this.active0= [],
                this.active1= [],
                this.active2= [],
                this.active301= [],
                this.active302=[],
                this.active303= [],
                this.active304= [],
                this.targetid=[],
                this.dialogvisible= false,
                // 附件地址--文件夹名
                this.a0addr= null,
                this.a1addr= null,
                this.a2addr= null,
                this.a301addr= null,
                this.a302addr= null,
                this.a303addr= null,
                this.a304addr= null,
                this.currentFile= null,
                this.currentFile301= null,
                this.currentFile302= null,
                this.currentFile303= null,
                this.currentFile304= null,
                this.a1UpFiles= null,
                this.a301UpFiles= null,
                this.a302UpFiles= null,
                this.a303UpFiles= null,
                this.a304UpFiles= null,
                this.$post('/shoppingui/bidShopping/getOneTarget', {targetid: id,memberid: this.getUser().id}).then((data) => {
                if (data.code == 0) {
                    this.oneTarget = data.data

                    this.id = data.data.id
                    this.targetid = data.data.targetid
                    // this.a2role = data.data.servicestate
                    this.dataid = data.data.id
                    this.active = data.data.active
                    this.a0addr = data.data.fileAddress
                    this.getLista0(this.a0addr)
                    this.a1addr = data.data.serviceaddress
                    this.getLista1(this.a1addr)
                    this.a2addr = data.data.resultaddress
                    this.getLista2(this.a2addr)
                    this.a301addr = data.data.transactionaddress
                    this.getLista301(this.a301addr)
                    this.a302addr = data.data.bondaddress
                    this.getLista302(this.a302addr)
                    this.a303addr = data.data.addtaxaddress
                    this.getLista303(this.a303addr)
                    this.a304addr = data.data.contractaddress
                    this.getLista304(this.a304addr)

                    if(this.active==2){
                        this.disa104 = false
                    }else {
                        this.disa104 = true
                    }
                    this.dialogvisible = true
                    this.servicemsg = data.data.servicemsg
                    this.fundmsg = data.data.fundmsg
                } else {
                this.$message({
                    message: '标的物未明确',
                    type: 'error',
                    showClose: 'true'
                })
                }
            })
            },
            next() {
                const datatemp = {
                    id: this.dataid,
                    serviceaddress: this.a1addr,
                    transactionaddress: this.a301addr,
                    bondaddress: this.a302addr,
                    addtaxaddress: this.a303addr,
                    contractaddress: this.a304addr,
                    servicestate: this.oneTarget.servicestate,
                    fundstate: this.oneTarget.fundstate
                }
                if(this.active == 2 && JSON.stringify(this.a301addr) == '[]'){
                   this.$message({
                    message: '请上传交易凭证！',
                    type: 'error',
                    showClose: 'true'
                })
                  return false
                }

                this.$post('/shoppingui/bidShopping/nextaddr',datatemp ).then((data) => {
                if (data.code == 0) {
                  if(this.active>1){
                  this.godown(null, 'up:',1)
                  }else {
                    this.godown(null, 'up:',0)
                  }
                    this.$message({
                    message: '已提交，等待审核',
                    type: 'success',
                    showClose: 'true'
                })

                this.active++
                if(this.active==2){
                    this.disa104 = false
                }else {
                    this.disa104 = true
                }
                } else {
                this.$message({
                    message: '标的物未明确',
                    type: 'error',
                    showClose: 'true'
                })
                }
            })

            },
            // 删除active -a0
             removeElement(index, fileName) {
                this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                    UploadService.delete(this.a0addr, fileName).then((response) => {
                        if (response.data === true) {
                        this.active0.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        } else {
                        this.$message({
                            type: "error",
                            message: "删除失败，请重试!",
                        });
                        }
                    });
                    })
                    .catch(() => {});
                },
                // 上传附件
    selectFilea1() {
      this.a1UpFiles = this.$refs.a1fileref.files;
      this.uploada1()
    },
    uploada1() {
      const _this = this;
      this.progress = 0;
      this.currentFile = this.a1UpFiles.item(0);
      if (!_this.a1addr) {
        _this.a1addr = new Date().getTime();
      }
      UploadService.upload(
        this.currentFile,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        },
        _this.a1addr
      )
        .then((response) => {
          this.message = response.data.message;
          return UploadService.getFilesa1(_this.a1addr);
        })
        .then((files) => {
          this.active1 = files.data;
        })
        .catch(() => {
            this.progress =0
             this.currentFile = null;
        });
    },
     // 删除active -a0
             removeElementa1(index, fileName) {
                this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                    UploadService.delete(this.a1addr, fileName).then((response) => {
                        if (response.data === true) {
                        this.active1.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        } else {
                        this.$message({
                            type: "error",
                            message: "删除失败，请重试!",
                        });
                        }
                    });
                    })
                    .catch(() => {});
                },
             removeElementa301(index, fileName) {
                this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    UploadService.delete(this.a301addr, fileName).then((response) => {
                        if (response.data === true) {
                        this.active301.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        } else {
                        this.$message({
                            type: "error",
                            message: "删除失败，请重试!",
                        });
                        }
                    });
                    })
                    .catch(() => {});
             },
             removeElementa302(index, fileName) {
                this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    UploadService.delete(this.a302addr, fileName).then((response) => {
                        if (response.data === true) {
                        this.active302.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        } else {
                        this.$message({
                            type: "error",
                            message: "删除失败，请重试!",
                        });
                        }
                    });
                    })
                    .catch(() => {});
             },
             removeElementa303(index, fileName) {
                this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    UploadService.delete(this.a303addr, fileName).then((response) => {
                        if (response.data === true) {
                        this.active303.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        } else {
                        this.$message({
                            type: "error",
                            message: "删除失败，请重试!",
                        });
                        }
                    });
                    })
                    .catch(() => {});
             },
             removeElementa304(index, fileName) {
                this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    UploadService.delete(this.a304addr, fileName).then((response) => {
                        if (response.data === true) {
                        this.active304.splice(index, 1);
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });
                        } else {
                        this.$message({
                            type: "error",
                            message: "删除失败，请重试!",
                        });
                        }
                    });
                    })
                    .catch(() => {});
             },
                //后去附件地址--具体文件名
                getLista0(serviceaddress){
                  if (serviceaddress) {
                    UploadService.getFilesa0(serviceaddress).then((response) => {

                        this.active0 =  response.data
                    })
                }
                },
                getLista1(serviceaddress){
                  if (serviceaddress) {
                    UploadService.getFilesa1(serviceaddress).then((response) => {

                        this.active1 =  response.data
                    })
                }
                } ,
                getLista2(serviceaddress){
                  if (serviceaddress) {
                    UploadService.getFilesa2(serviceaddress).then((response) => {

                        this.active2 =  response.data

                    })
                }
                },
                getLista301(serviceaddress){
                  if (serviceaddress) {
                    UploadService.getFilesa301(serviceaddress).then((response) => {

                        this.active301 =  response.data
                    })
                }
                },
                getLista302(serviceaddress){
                  if (serviceaddress) {
                    UploadService.getFilesa302(serviceaddress).then((response) => {

                        this.active302 =  response.data
                    })
                }
                },
                getLista303(serviceaddress){
                  if (serviceaddress) {
                    UploadService.getFilesa303(serviceaddress).then((response) => {

                        this.active303 =  response.data
                    })
                }
                } ,
                getLista304(serviceaddress){
                  if (serviceaddress) {
                    UploadService.getFilesa304(serviceaddress).then((response) => {

                        this.active304 =  response.data
                    })
                }
                },
    selectFilea301() {
      this.a301UpFiles = this.$refs.a301fileref.files;
      this.uploada301()
    },
    uploada301() {
      const _this = this;
      this.progress = 0;
      this.currentFile301 = this.a301UpFiles.item(0);
      if (!_this.a301addr) {
        _this.a301addr = new Date().getTime();
      }
      UploadService.upload(
        this.currentFile301,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        },
        _this.a301addr
      )
        .then((response) => {
          this.message = response.data.message;
        //   return UploadService.getFiles(_this.a301addr);
            return UploadService.getFilesa301(_this.a301addr);
        })
        .then((files) => {
          this.active301 = files.data;
        })
        .catch(() => {
            this.progress =0
             this.currentFile301 = null;
        });
    },
    selectFilea302() {
      this.a302UpFiles = this.$refs.a302fileref.files;
      this.uploada302()
    },
    uploada302() {
      const _this = this;
      this.progress = 0;
      this.currentFile302 = this.a302UpFiles.item(0);
      if (!_this.a302addr) {
        _this.a302addr = new Date().getTime();
      }
      UploadService.upload(
        this.currentFile302,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        },
        _this.a302addr
      )
        .then((response) => {
          this.message = response.data.message;
          return UploadService.getFilesa302(_this.a302addr);
        })
        .then((files) => {
          this.active302 = files.data;
        })
        .catch(() => {
            this.progress =0
             this.currentFile302 = null;
        });
    },
    selectFilea303() {
      this.a303UpFiles = this.$refs.a303fileref.files;
      this.uploada303()
    },
    uploada303() {
      const _this = this;
      this.progress = 0;
      this.currentFile303 = this.a303UpFiles.item(0);
      if (!_this.a303addr) {
        _this.a303addr = new Date().getTime();
      }
      UploadService.upload(
        this.currentFile303,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        },
        _this.a303addr
      )
        .then((response) => {
          this.message = response.data.message;
          return UploadService.getFilesa303(_this.a303addr);
        })
        .then((files) => {
          this.active303 = files.data;
        })
        .catch(() => {
            this.progress =0
             this.currentFile303 = null;
        });
    },
    selectFilea304() {
      this.a304UpFiles = this.$refs.a304fileref.files;
      this.uploada304()
    },
    uploada304() {
      const _this = this;
      this.progress = 0;
      this.currentFile304 = this.a304UpFiles.item(0);
      if (!_this.a304addr) {
        _this.a304addr = new Date().getTime();
      }
      UploadService.upload(
        this.currentFile304,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        },
        _this.a304addr
      )
        .then((response) => {
          this.message = response.data.message;
          return UploadService.getFilesa304(_this.a304addr);
        })
        .then((files) => {

          this.active304 = files.data;
        })
        .catch(() => {
            this.progress =0
             this.currentFile304 = null;
        });
    },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/index.css";
    @import "../assets/css/page/personalCenter.scss";
    @import "../assets/css/page/mine.scss";

 ::v-deep .el-step.is-center .el-step__description{
     font-size: 15px;
}
.file {
    position: relative;
    margin-top: 5%;
    margin-left: 12%;
    display: inline-block;
    background: #D0EEFF;
    border: 1px solid #99D3F5;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    color: #1E88C7;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
}
.file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
}
.file:hover {
    background: #AADFFD;
    border-color: #78C3F3;
    color: #004974;
    cursor: pointer;
    text-decoration: none;
}
.filein{
    color:red;
    font-size: 18px;
    margin-left:6%;
    text-decoration:underline;
    cursor: pointer;
}
.foreUp{
    margin-left: 3%;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;
    &>div{
        flex: 1;
        // border: 1px solid blue;


    }
}

</style>
