<template>
    <el-dialog :visible.sync="visible" title="附件下载" :close-on-click-modal="false" :close-on-press-escape="false" width="70%">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="210px">
            <el-collapse v-model="activeNames">
                <el-collapse-item title="报名资料" name="1">
                    <el-row>
                        <el-col :span='11'>
                            <el-form-item label="报名资料列表">
                                <el-table :data="bomingfilefileInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                    <!--                            <el-table-column   v-if="!readonly1" fixed="right" label="操作" width="120">-->
                                    <!--                                <template slot-scope="scope">-->
                                    <!--                                    <el-button-->

                                    <!--                                            @click.native.prevent="removeElement(scope.$index, scope.row.name)"-->
                                    <!--                                            type="text"-->
                                    <!--                                            size="small"-->
                                    <!--                                    >-->
                                    <!--                                        移除-->
                                    <!--                                    </el-button>-->
                                    <!--                                </template>-->
                                    <!--                            </el-table-column>-->
                                </el-table>
                            </el-form-item>
                        </el-col>
                        <el-col :span='11' v-if="depfileInfos.length > 0">
                            <el-form-item label="报名保证金列表">
                                <el-table :data="depfileInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                    <!--                            <el-table-column   v-if="!readonly1" fixed="right" label="操作" width="120">-->
                                    <!--                                <template slot-scope="scope">-->
                                    <!--                                    <el-button-->

                                    <!--                                            @click.native.prevent="removeElement(scope.$index, scope.row.name)"-->
                                    <!--                                            type="text"-->
                                    <!--                                            size="small"-->
                                    <!--                                    >-->
                                    <!--                                        移除-->
                                    <!--                                    </el-button>-->
                                    <!--                                </template>-->
                                    <!--                            </el-table-column>-->
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="中标资料" name="2">
                    <el-row>
                        <el-col :span='11' v-if="paynoticeInfos.length > 0">
                            <el-form-item label="缴费通知书">
                                <el-table :data="paynoticeInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a @click="downloadFile()">缴费通知书</a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>
                        <el-col :span='11' v-if="serviceaddressInfos.length > 0">
                            <el-form-item label="交费凭证">
                                <el-table :data="serviceaddressInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='11' v-if="resultaddressfileInfos.length > 0">
                            <el-form-item label="竞价结果通知书">
                                <el-table :data="resultaddressfileInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>
                        <el-col :span='11' v-if="transactionaddressInfos.length > 0">
                            <el-form-item label="交易合同">
                                <el-table :data="transactionaddressInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>

                        <el-col :span='11' v-if="bondaddressInfos.length > 0">
                            <el-form-item label="交易价款凭证">
                                <el-table :data="bondaddressInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>

                        <el-col :span='11' v-if="addtaxaddressInfos.length > 0">
                            <el-form-item label="履约保证金凭证">
                                <el-table :data="addtaxaddressInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>
                        <el-col :span='11' v-if="contractaddressInfos.length > 0">
                            <el-form-item label="交易确认函">
                                <el-table :data="contractaddressInfos" style="width: 100%" max-height="250">
                                    <el-table-column prop="name" label="" >
                                        <template slot-scope="scope">
                                            <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
            </el-collapse>

        </el-form>
        <template slot="footer">
            <el-button @click="visible = false">{{'取消' }}</el-button>
<!--            <el-button v-if="dataForm.auditstate!== 1" type="primary" @click="dataAuditHandle(1)">取消</el-button>-->
<!--            <el-button v-if="dataForm.auditstate!== 2" type="primary" @click="bohuiHandle(2)">驳回</el-button>-->
        </template>

    </el-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import UploadService from "../services/UploadFilesService";
    import axios from "axios";
    export default {
        name: "FileView",
        data () {
            return {
                activeNames: ['1'],
                selectedFiles: undefined,
                currentFile: undefined,
                progress: 0,
                message: '',
                bomingfilefileInfos: [],
                depfileInfos: [],
                serviceaddressInfos: [],
                resultaddressfileInfos: [],
                paynoticeInfos: [],
                contractaddressInfos: [],
                bondaddressInfos: [],
                addtaxaddressInfos: [],
                transactionaddressInfos: [],
                currentId: '',
                readonly1: false,

                readonly: true,

                visible: false,

                dataForm: {
                    auditerName: '',
                    targetname: '',
                    enterprisename: '',
                    membername: '',
                    id: '',
                    targetid: '',
                    memberid: '',
                    enterid: '',
                    fileaddress: '',
                    auditstate: '',
                    isDelete: '',
                    auditTime: '',
                    createTime: '',
                    updateTime: '',
                    auditer: '',
                    creater: '',
                    modifier: '',
                    erroMsg: '',
                },
                userInfo:{
                    addr: '',
                    areacode: '',
                    bank: '',
                    bankcode: '',
                    birthday: '',
                    blacklist: [],
                    enterprisecode: '',
                    enterpriseid: '',
                    enterprisename: '',
                    enterprisenet: '',
                    enterprisenscale: '',
                    enterpriseshortname: '',
                    id: '',
                    industry: '',
                    isloading: '',
                    legal: '',
                    legalcode: '',
                    location: '',
                    membercode: '',
                    membername: '',
                    membership: '',
                    operatingyears: '',
                    productname: '',
                    sex: '',
                    tel: '',
                    tenantid: ''
                },
                fileInfo: {
                    id: '',
                    targetid: '',
                    memberid: '',
                    bomingfile: '',
                    depositinfofileaddress: '',
                    serviceaddress: '',
                    resultaddress: '',
                    transactionaddress: '',
                    bondaddress: '',
                    bid: ''
                }
            }
        },
        computed: {
            dataRule () {
                return {

                }
            }
        },
        methods:{
            ...mapGetters(["getUser", "getNum"]),
            init (targetId) {
                this.visible = true
                this.dataForm.targetid = targetId;
                this.userInfo = this.getUser()
                this.paynoticeInfos = [];
                this.$nextTick(() => {
                    if (this.dataForm.targetid) {
                        this.getInfo()
                    }
                })
            },
            myurl(url) {
                if (url != null) {
                    var reg = RegExp(/shoppingui/);
                    if (reg.test(url)) {
                        const index = url.indexOf("shoppingui")
                        const res = this.$urldown + url.substring(index)
                        return res
                    }
                } else {
                    return null
                }
            },
            getInfo () {
                this.$post('/shoppingui/bidShopping/getBaoMingFile' , {
                    memberid: this.userInfo.id,
                    targetid: this.dataForm.targetid
                }).then((res) => {
                    if (res.code !== 0) {
                        return this.$message.error(res.msg)
                    }
                    console.log(res)
                    this.fileInfo = {
                        ...this.fileInfo,
                        ...res.data
                    }
                    const bomingfile = res.data.bomingfile
                    if (bomingfile) {
                        UploadService.getFiles(bomingfile).then((response) => {
                            this.bomingfilefileInfos = response.data
                        })
                    }
                    const depositinfofileaddress = res.data.depositinfofileaddress
                    if (depositinfofileaddress) {
                        UploadService.getFiles(depositinfofileaddress).then((response) => {
                            this.depfileInfos = response.data
                        })
                    }


                    const bid = res.data.bid
                    if (bid) {
                        this.paynoticeInfos.push('1');
                    }

                    const serviceaddress = res.data.serviceaddress
                    if (serviceaddress) {
                        UploadService.getFiles(serviceaddress).then((response) => {
                            this.serviceaddressInfos = response.data
                        })
                    }
                    const resultaddress = res.data.resultaddress
                    if (resultaddress) {
                        UploadService.getFiles(resultaddress).then((response) => {
                            this.resultaddressfileInfos = response.data
                        })
                    }
                    const transactionaddress = res.data.transactionaddress
                    if (transactionaddress) {
                        UploadService.getFiles(transactionaddress).then((response) => {
                            this.transactionaddressInfos = response.data
                        })
                    }
                    const bondaddress = res.data.bondaddress
                    if (bondaddress) {
                        UploadService.getFiles(bondaddress).then((response) => {
                            this.bondaddressInfos = response.data
                        })
                    }
                    const contractaddress = res.data.contractaddress
                    if (contractaddress) {
                        UploadService.getFiles(contractaddress).then((response) => {
                            this.contractaddressInfos = response.data
                        })
                    }

                    const addtaxaddress = res.data.addtaxaddress
                    if (addtaxaddress) {
                        UploadService.getFiles(addtaxaddress).then((response) => {
                            this.addtaxaddressInfos = response.data
                        })
                    }
                }).catch(() => {})
            },

            removeElement (index, fileName) {
                // console.log(this.dataForm.fileaddress)
                // console.log(this.fileInfos[index])
                UploadService.delete(this.dataForm.fileaddress, fileName).then((response) => {
                    if (response.data === true) {
                        this.fileInfos.splice(index, 1);
                    } else {
                        this.message = 'Delete file was failed';
                    }
                })
            },
            downloadFile () {
                axios.post( '/shoppingui/bidShopping/exportWord', {
                    targetid: this.dataForm.targetid,
                    memberid: this.getUser().id,
                }, {
                    responseType: 'blob'
                }).then(function(response) {

                    var fileName = '交费通知书.doc'
                    const blob = new Blob([response.data],{type: 'application/octet-stream'})
                    try {
                        const blobUrl = window.URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.style.display = 'none'
                        a.download = fileName
                        a.href = blobUrl
                        a.click()
                    } catch (e) {
                        this.$message.error('文件下载失败，请稍后重试！')
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
