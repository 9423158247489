<template>
    <el-dialog :visible.sync="visible" title="填写发票信息" :close-on-click-modal="false" :close-on-press-escape="false" width="70%">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="210px">
            <el-form-item label="名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="名称"></el-input>
            </el-form-item>
            <el-form-item label="税号" prop="taxNumber">
                <el-input v-model="dataForm.taxNumber" placeholder="税号"></el-input>
            </el-form-item>
            <el-form-item label="单位地址" prop="address">
                <el-input v-model="dataForm.address" placeholder="单位地址"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="tel">
                <el-input v-model="dataForm.tel" placeholder="电话" maxlength="14"></el-input>
            </el-form-item>
            <el-form-item label="开户银行" prop="bankName">
                <el-input v-model="dataForm.bankName" placeholder="开户银行"></el-input>
            </el-form-item>
            <el-form-item label="银行账户" prop="bankNumber">
                <el-input v-model="dataForm.bankNumber" placeholder="银行账户"></el-input>
            </el-form-item>
            <el-form-item label="发票类型" prop="taxtype">
                <el-select v-model="dataForm.taxtype" placeholder="请选择">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template slot="footer">
            <el-button @click="visible = false">{{'取消' }}</el-button>
            <el-button type="primary" @click="saveAndUpdate()">{{ '保存' }}</el-button>
<!--            <el-button v-if="dataForm.auditstate!== 2" type="primary" @click="bohuiHandle(2)">驳回</el-button>-->
        </template>

    </el-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import UploadService from "../services/UploadFilesService";
    import axios from "axios";
    export default {
        name: "TaxView",
        data () {
            return {
                options: [{
                    value: 0,
                    label: '增值税普通发票'
                }, {
                    value: 1,
                    label: '增值税专用发票'
                }],
                selectedFiles: undefined,
                currentFile: undefined,
                progress: 0,
                message: '',
                bomingfilefileInfos: [],
                depfileInfos: [],
                serviceaddressInfos: [],
                resultaddressfileInfos: [],
                paynoticeInfos: [],
                contractaddressInfos: [],
                bondaddressInfos: [],
                addtaxaddressInfos: [],
                transactionaddressInfos: [],
                currentId: '',
                readonly1: false,

                readonly: true,

                visible: false,

                dataForm: {
                    outbidresultid:'',
                    id: '',
                    targetid: '',
                    memberid: '',
                    enterid: '',
                    name: '',
                    taxNumber: '',
                    address: '',
                    tel: '',
                    bankName: '',
                    bankNumber: '',
                    isDelete: '',
                    createTime: '',
                    updateTime: '',
                    creator: '',
                    modifier: '',
                    taxtype: '',
                },
                userInfo:{
                    addr: '',
                    areacode: '',
                    bank: '',
                    bankcode: '',
                    birthday: '',
                    blacklist: [],
                    enterprisecode: '',
                    enterpriseid: '',
                    enterprisename: '',
                    enterprisenet: '',
                    enterprisenscale: '',
                    enterpriseshortname: '',
                    id: '',
                    industry: '',
                    isloading: '',
                    legal: '',
                    legalcode: '',
                    location: '',
                    membercode: '',
                    membername: '',
                    membership: '',
                    operatingyears: '',
                    productname: '',
                    sex: '',
                    tel: '',
                    tenantid: ''
                },
                fileInfo: {
                    id: '',
                    targetid: '',
                    memberid: '',
                    bomingfile: '',
                    depositinfofileaddress: '',
                    serviceaddress: '',
                    resultaddress: '',
                    transactionaddress: '',
                    bondaddress: '',
                    bid: ''
                }
            }
        },
        computed: {
            dataRule () {
                return {
                    name: [
                        { required: true, message: '必填项不能为空', trigger: 'blur' }
                    ],
                    taxNumber: [
                        { required: true, message: '必填项不能为空', trigger: 'blur' }
                    ],
                    taxtype: [
                        { required: true, message: '必填项不能为空', trigger: 'blur' }
                    ]
                }
            }
        },
        methods:{
            ...mapGetters(["getUser", "getNum"]),
            init (targetid,outresultid) {
                this.visible = true
                console.log(this.dataForm.targetid);
                console.log(this.dataForm.outbidresultid);
                this.$nextTick(() => {
                    this.$refs.dataForm.resetFields();
                    this.dataForm.targetid = targetid;
                    this.dataForm.outbidresultid = outresultid;
                    this.userInfo = this.getUser()
                    this.paynoticeInfos = [];
                    if (this.dataForm.targetid) {
                        this.getInfo()
                    }
                })
            },

            getInfo () {
                this.$post('/shoppingui/taxinfo/getTaxInfo' , {
                    memberid: this.userInfo.id,
                    targetid: this.dataForm.targetid
                }).then((res) => {
                    if (res.code !== 0) {
                        return this.$message.error(res.msg)
                    }
                    console.log(res)
                    if (res.data != null) {
                        this.dataForm = {
                            ...this.dataForm,
                            ...res.data
                        }
                    }
                }).catch(() => {})
            },

            // 表单提交
            saveAndUpdate () {
                this.$refs.dataForm.validate((valid) => {
                    if (!valid) {
                        return false
                    }
                    this.dataForm.memberid = this.userInfo.id
                    this.$post('/shoppingui/taxinfo/save', this.dataForm).then((res) => {
                        if (res.code !== 0) {
                            return this.$message.error(res.msg)
                        }
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration: 500,
                            onClose: () => {
                                this.visible = false
                            }
                        })
                    }).catch(() => {})
                })
            },
        }
    }
</script>

<style scoped>

</style>
